@font-face {
    font-family: 'Fira Sans';
    src: url('/assets/fonts/FiraSans-SemiBold.woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Regular.woff2');
    font-weight: normal;
    font-style: normal;
}

