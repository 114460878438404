.contact-form-container {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr;
    grid-template-areas: "illustration"
                         "form";
    margin-bottom: 1rem;

    @media screen and (min-width: 960px) {
        grid-template-areas: "form illustration";
        grid-template-columns: 65% 35%;
        grid-template-rows: 1fr;
    }
}

.contact-form {
    grid-area: form;
    margin-inline: -1rem;
    padding: 1rem;
    position: relative;
}

.contact-illustration {
    display: none;
    @media screen and (min-width: 960px) {
        display: grid;
        padding: 2rem;
        place-content: center;
    }

    .contact-svg {
        max-width: 100%;

        .letter:nth-child(2) {
            animation: pop 10s linear alternate infinite;
        }
        .letter:nth-child(3) {
            animation: pop 8s linear both alternate-reverse infinite;
        }

        @keyframes pop {
            from {
                transform: translateY(15px);
            }
            to {

                transform: translateY(-15px);
            }
        }
    }
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.input-group__error {
    align-items: center;
    color: var(--error);
    display: flex;
    gap: 0.5rem;
    height: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
    transition: transform .3s var(--ease), opacity .3s var(--ease);

    &.shown {
        height: auto;
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    }
}


.input[type="text"],
.input[type="email"] {
    background: rgba(0 0 0 / 0.25);
    border: 1px solid var(--input-background);
    border-radius: var(--border-radius);
    color: var(--fore-light);
    font-size: 1rem;
    outline: none;
    padding: 0.5rem;
    resize: vertical;
    transition: background-color .3s var(--ease);

    &:focus {
        background: var(--input-focus-background);
    }

    &.has-error {
        background-color: var(--input-error-background);
        border-color: var(--error);
    }

    &[disabled] {
        color: var(--fore-disabled);
        border-color: var(--border-color-disabled);

    }
}

.contact-form.sent .input {
    color: var(--fore-ok);
    border-color: var(--border-color-ok);
}

textarea.input[type="text"] {
    min-height: 12rem;
}

.form-button {
    background-color: var(--accent-1);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--fore-dark);
    display: block;
    margin-inline: auto;
    padding: 0.75rem 1.5rem;

    &[disabled] {
        cursor: not-allowed;
        background: var(--border-color-disabled);
        border-color: var(--border-color-disabled);
    }
}

.contact-form.sent .form-button {
    background-color: var(--border-color-ok);
    border-color: var(--border-color-ok);
}

