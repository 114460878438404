.hero {
    align-items: flex-start;
    color: var(--fore-light);
    display: flex;
    gap: 2rem;

    &__title,
    &__subtitle {
        opacity: 0;
        transform: translateX(-100%);
    }

    &__title {
        animation: hero-enter 1s 0s var(--ease) forwards;
        font-size: 2.5rem;
        @media screen and (min-width: 600px) {
            font-size: 3rem;
        }
    }
    &__subtitle {
        animation: hero-enter 1s 0.1s var(--ease) forwards;
        font-size: 1.25rem;
        margin-top: -0.25rem;
        &::before {
            background: var(--accent-1);
            content: '';
            display: inline-block;
            height: 0.25rem;
            margin-right: 1rem;
            vertical-align: middle;
            width: 5rem;
        }
        @media screen and (min-width: 600px) {
            font-size: 1.5rem;
        }
    }

    &__image-container {
        animation: image-container-enter 1s 0.25s var(--ease) forwards;
        font-size: 0;
        grid-area: image;
        line-height: 0;
        opacity: 0;
        transform: translateX(-50px);
        position: relative;

        @keyframes image-container-enter {
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    &__image {
        border-radius: 50%;
        max-width: 160px;
    }

    @media screen and (max-width: 487px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@keyframes hero-enter {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
