.tech-icons {
    user-select: none;
    pointer-events: none;
}

.tech-icon {
    max-height: 2rem;
    @media screen and (min-width: 600px) {
        max-height: 3rem;
    }
}

