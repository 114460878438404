.network {
    animation: network-enter 1s .25s var(--ease) forwards;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding: 0.5rem;
    transform: translateY(50px);
    opacity: 0;

    @keyframes network-enter {
        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    @media screen and (max-width: 487px) {
        justify-content: center;
    }
}

.network__link {
    color: var(--fore-light);
    font-size: 1.75rem;
    text-decoration: none;
    transition: color .3s var(--ease);

    &.github:hover {
        color: var(--github);
    }
    &.gitlab:hover {
        color: var(--gitlab);
    }
    &.docker:hover {
        color: var(--docker);
    }
    &.linkedin:hover {
        color: var(--linkedin);
    }
    &.instagram:hover {
        color: var(--instagram);
    }
    &.youtube:hover {
        color: var(--youtube);
    }
}
