.flex-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
}

.nav-limiter {
    max-width: 1080px;
    margin-inline: auto;
}

.navigation {
    background: var(--nav-bg);
    background-size: 100%;
    display: flex;
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 200;
    width: 100%;

    &__item {
        align-items: center;
        color: var(--fore-light);
        display: inline-flex;
        text-decoration: none;
        transition: all .3s var(--ease);

        &:hover {
            color: var(--accent-2);
        }

        &:not(:last-child) {
            &::after {
                background: var(--fore-light);
                content: '';
                display: inline-block;
                height: .4rem;
                transform: rotateZ(45deg);
                margin-left: 1rem;
                width: .4rem;
            }
        }
    }
}