.cta-button {
    border: 2px solid var(--fore-light);
    color: var(--fore-light);
    font-size: 1.125rem;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
    transition: all .3s var(--ease);

    &:hover {
        background: var(--fore-light);
        color: var(--fore-dark);
    }
}

#my-work-btn {
    animation: my-work-btn-enter 1s .5s var(--ease) forwards;
    display: inline-block;
    opacity: 0;
    transform: translateX(100%);
}

@keyframes my-work-btn-enter {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}