@import "./variables.scss";
@import "./fonts/fonts.scss";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    scrollbar-color: var(--accent-1) hsla(0, 0%, 0%, 0.9);
    scrollbar-width: thin;
}

*::before, *::after {
    box-sizing: border-box;
}

html {
    background-color: #121616;
    min-height: 100vh;
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow-x: hidden;
    scroll-padding-top: 4rem;
}

h1, h2, h3,
h4, h5, h6 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 500;
}

#bg-canvas {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
}

@import "./blocks/hero";
@import "./blocks/content";
@import "./blocks/network";
@import "./blocks/navigation";
@import "./special/icons";
@import "./special/buttons";
@import "./special/scroller";
@import "./blocks/forms";

.alert-red {
    color: var(--error);
    font-weight: bold;
}

.link {
    text-decoration: none;
    color: var(--accent-1);
}

::-webkit-scrollbar-track {
    background: hsla(0, 0%, 0%, 0.9);
    border-radius: 4px;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    background: var(--accent-1);
    border-radius: 4px;
}

.hidden {
    display: none;
}
