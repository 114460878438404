$primary: #084A67;
$accent1: #337dbe;
$accent1: #c62f2c;
$accent1: #A594FD;
$accent2: #6c7061;
$accent2: #C72C77;
$err: #F02323;
$border-color: #1d1e34;
$input-background: rgb(40 40 40 / .59);
$input-focus-background: rgb(40 40 40 / .9);
$input-error-background: rgb(142 0 0 / .15);
$card-background: rgb(40 40 40 / .59);

:root {
    --primary: #{$primary};
    --accent-1: #{$accent1};
    --accent-1-dark: #{darken($accent1, 5)};
    --accent-2: #{$accent2};
    --accent-2-dark: #{darken($accent2, 5)};

    --border-radius: 4px;
    --card-border-radius: 6px;
    --border-color: #{$border-color};
    --border-color-disabled: rgb(182 142 182);
    --border-color-ok: rgb(142 182 142);
    --card-background: #{$card-background};
    --fore-light: #F0F0FF;
    --fore-dark: #222253;
    --fore-disabled: rgb(182 142 182);
    --fore-ok: rgb(142 182 142);
    --background: #090B0B;
    --nav-bg: #{rgba(#00171F, 0.25)};

    --card-gradient-background: #{rgba($border-color, 0.8)};
    --card-accent-base-color: #{rgba(darken($accent1, 50), 0.3)};
    --disabled: #d1d1d1;

    --input-background: #{$input-background};
    --input-focus-background: #{$input-focus-background};
    --input-error-background: #{$input-error-background};

    --github: #9DF4D3;
    --gitlab: #FC6D26;
    --docker: #007BFF;
    --instagram: #DE0A58;
    --linkedin: #0A66C2;
    --youtube: #FF0000;
    --error: #{$err};

    --ease: cubic-bezier(0.4, 0.0, 0.2, 1);
}
