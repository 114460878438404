.scroller {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    overflow: hidden;

    &__inner {
        width: fit-content;
    }
}
.scroller[data-animate=true] {
    mask: linear-gradient(90deg, transparent, white 25%, white 75%, transparent);
}
.scroller[data-animate=true] .scroller__inner {
    animation: scroll 25s linear infinite;
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
}

@keyframes scroll {
    to {
        transform: translateX(calc(-50% - 1rem));
    }
}
