.content {
    color: var(--fore-light);
    padding-top: 0;
    min-height: 100vh;
}

.page {
    margin-inline: auto;
    max-width: 1080px;
    padding: 2rem;
    position: relative;

    &:not(.main-page) {
        animation: default-enter 1s .25s var(--ease) forwards;
        transform: translateY(4rem);
        opacity: 0;
    }

    &__title {
        font-size: 2rem;
        margin: 1.5rem 0;
    }

    &__description {
        font-size: 1.125rem;
        line-height: 1.6;
        margin-bottom: 1rem;
    }
}

.description,
.technologies {
    animation: default-enter 1s .25s var(--ease) forwards;
    font-size: 1.125rem;
    line-height: 1.6;
    opacity: 0;
    transform: translateY(4rem);
}

.technologies,
.description {
    margin: 2rem 0;
}

@keyframes default-enter {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.bg-text {
    display: grid;
    filter: blur(10px);
    font-size: min(30vh, 30vw);
    font-weight: 800;
    height: 100vh;
    left: 50%;
    opacity: .5;
    place-items: center;
    pointer-events: none;
    position: fixed;
    text-transform: uppercase;
    text-align: center;
    top: 0;
    transform: translateX(-50%);
}

.qualities {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-areas: 'taught' 'webdev' 'network' 'server';
    margin-block: 1rem;

    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas: 'taught taught webdev webdev webdev webdev'
                             'network network network server server server';
    }
}

.quality__item {
    backdrop-filter: blur(2px);
    background-color: var(--card-background);
    background: radial-gradient(ellipse at top, var(--card-gradient-background), transparent),
                radial-gradient(ellipse at bottom, rgba(0 0 0 / .5), transparent)
                url(#noiseFilter);
    border: 2px solid var(--border-color);
    border-radius: var(--card-border-radius);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: default;
    line-height: 1.8;
    padding: 1rem;

    &-title {
        font-size: 1.25rem;
    }

    &-description {
        font-size: 0.9rem;
    }

    &[data-quality="self-taught"] {
        grid-area: taught;
    }

    &[data-quality="web-development"] {
        grid-area: webdev;
    }

    &[data-quality="server-management"] {
        grid-area: server;
    }

    &[data-quality="networking"] {
        grid-area: network;
    }
}

.journey {
    backdrop-filter: blur(2px);
    background-image: radial-gradient(circle at -4500px -3500px, var(--card-accent-base-color), black);
    border: 1px solid var(--border-color);
    border-radius: var(--card-border-radius);
    list-style-type: none;
    overflow: hidden;
    padding: 1rem 2rem;
    position: relative;

    &::before {
        background-image: url('/assets/img/grain.jpg');
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.075;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &:after {
        content: '';
        position: absolute;
        width: 0.25rem;
        height: 100%;
        background: darken(white, 10);
        left: 2rem;
        top: 0;
        mask: linear-gradient(transparent 0%, white 15%, transparent 100%);
    }
}

.journey-item {
    margin-block: 1rem;
    padding-left: 2rem;

    .item__title {
        font-size: 1.25rem;
        margin-bottom: 0.5em;
        position: relative;

        &::before {
            background-color: white;
            border-radius: 1rem;
            content: '';
            position: absolute;
            top: 0.4rem;
            left: -2.25rem;
            width: 0.75rem;
            height: 0.75rem;
            z-index: 10;
        }

    }

    .item__position {
        background: var(--card-gradient-background);
        border: 1px solid var(--border-color);
        border-radius: 1rem;
        display: inline-block;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
        padding: 0.25rem 0.5rem;
    }

    .item__description {
        line-height: 1.8;
    }
}

.journey .journey-item:first-child {
    .item__title::before {
        box-shadow: 0 0 6px rgba(255 255 255 / .5), 0 0 0 6px rgb(255 255 255 / 0.2);
    }
}

@for $i from 1 through 5 {
    .journey .journey-item:nth-child(#{$i}) {
        .item__title::before {
            background: #{darken(white, $i * 10)};
        }
    }

}
